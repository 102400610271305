import React from "react";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import SEO from "../../components/seo";

export default () => (
  <>
    <SEO title="Thank you" />

    <Container maxWidth="md" style={{ paddingTop: "150px" }}>
      <Typography component="div">
        <Box
          textAlign="center"
          component="h1"
          m={1}
          fontFamily="DM Serif Text"
          fontWeight="700"
          color="#3d3d3d"
          fontSize="h2.fontSize"
        >
          Thank you!
        </Box>
        <br />
        <Box color="#3d3d3d" component="p" textAlign="center">
          Your payment was successful. We will be in touch shortly to start the
          onboarding process.
        </Box>
      </Typography>
      <br />
      <br />
      <br />
      <br />
      <br />
    </Container>
  </>
);
